<template>
  <b-overlay :show="isLoading" rounded="lg">
    <b-card title="Daikin - Self Collect List">
      <b-col cols="12">
        <!--only show for mobile view, THIS IS FIRST PAGE MENU TO CHOOSE DATA BY STATUS-->
        <b-row class="hide-on-desktop" v-if="btnshow">
          <b-col cols="12">
            <b-row>
              <b-button
                size="lg"
                class="w-100 d-flex justify-content-center align-items-center"
                style="margin-bottom:15px;"
                variant="primary"
                @click="btnForMobile('Scanning Completed')"
              >
                Scanning Completed DO List
              </b-button>
            </b-row>
            <b-row>
              <b-button
                size="lg"
                class="w-100 d-flex justify-content-center align-items-center"
                style="margin-bottom:15px;"
                variant="primary"
                @click="btnForMobile('Collected')"
              >
                Collected DO List
              </b-button>
            </b-row>
            <b-row>
              <b-button
                size="lg"
                class="w-100 d-flex justify-content-center align-items-center"
                style="margin-bottom:15px;"
                variant="primary"
                @click="btnForMobile('All')"
              >
                All DO List
              </b-button>
            </b-row>
          </b-col>
        </b-row>
        <!--only show for mobile view, THIS IS SECOND PAGE MENU TO SHOW DATA TABLE-->
        <b-row :class="btnBackMobile">
          <b-button size="md" @click="actBackMobile" variant="primary">
            Back
          </b-button>
        </b-row>
        <b-row class="hide-on-desktop" v-if="showSortBy">
          <b-col md="6" style="margin-bottom:10px; margin-top:10px">
            <b-form-group
              label="Sort By"
              label-for="sort-by-select"
              label-cols-md="0"
              label-align-sm="left"
              label-size="md"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sort-by-select"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-75"
                >
                  <template #first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>

                <b-form-select
                  v-model="sortDesc"
                  :disabled="!sortBy"
                  :options="directionOptions"
                  size="sm"
                  class="w-25"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <!--end only show for mobile view-->
        <!--this is options for search data, only "Filter by Status" will dissapear on mobile view-->
        <b-row>
          <b-col md="6" class="ml-md-auto hide-on-mobile">
            <strong>Filter by Status :</strong>
            <b-form-select
              @input="(value) => statusFiltering(value)"
              id="delivery-status"
              v-model="deliveryStatus"
              :options="listStatus"
              @click="filterDelivery"
            >
            </b-form-select>
          </b-col>
          <b-col md="6" :class="conditionView">
            <b-form-input
              
              v-model="search"
              type="search"
              placeholder="Search by DO"
              style="float: right; margin-top: 20px; margin-bottom: 30px;"
            />
          </b-col>
          <b-col :class="conditionView" class="mb-1">
            <b-button @click="doFiltering" variant="primary" style="margin-right: 10px;">search</b-button>
            <span style="color: red;"></span>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6"> </b-col>
          <b-col md="6" :class="conditionView">
            <b-row>
              <b-col md="10" cols="9">
                <b-form-datepicker
                  @input="(value) => fireFiltering(value)"
                  placeholder="Search by Delivery Date"
                  v-model="delivDate"
                  style="float: right;  margin-bottom: 30px;"
                />
              </b-col>
              <b-col md="2" cols="3">
                <b-button variant="danger" @click="cleanQuery">X</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!--END this is options for search data-->
        <b-row :class="conditionView">
          <b-table
            responsive
            small
            hover
            bordered
            show-empty
            :items="filterDelivery"
            :fields="fields"
            :busy.sync="tableLoading"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :stacked="stackedStatus"
          >
            <template #cell(index)="row">
              <!-- {{ toSelfCollect(row.index + 1) }} -->
              {{ row.index + 1 + (currentPage - 1) * 10 }}
            </template>

            <template v-slot:cell(deliveryDate)="row">
              {{ dates(row.item.deliveryDate) }}
            </template>
            <template #cell(actions)="row">
              <b-dropdown
                id="dropdown-dropleft"
                :dropleft="showDropdown"
                variant="primary"
                size="sm"
                text="Menu"
                no-caret
              >
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item v-if="checkDetail(row.item)">
                  <b-button
                    size="sm"
                    variant="info"
                    @click="toDetailPage(row.item)"
                    class="mr-1"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item v-if="checkCollected(row.item)">
                  <b-button
                    size="sm"
                    variant="info"
                    @click="toSelfCollect(row.item)"
                    class="mr-1"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-row>
        <b-row :class="conditionView" style="margin-top:10px">
          <b-col md="9" />
          <b-col md="3">
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              @change="onPageChange"
              v-model="currentPage"
              align="fill"
              class="hide-on-desktop"
            />
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              @change="onPageChange"
              v-model="currentPage"
              class="hide-on-mobile"
              style="float: right"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/axios";
import lodash from "lodash";
import { userAccess, dateFormat } from "@/utils/utils";
export default {
  data() {
    let mobileViewQuery = window.matchMedia("(max-width: 761px)"); //check true or false width screen mobile view
    return {
      mobileViewQuery: mobileViewQuery, //all info matchMedia
      mobileView: mobileViewQuery.matches, // return true or false match screen moobile view
      //FIELD FOR MOBILE
      btnshow: true,
      showDropdown: true,
      btnBackMobile: "hide-at-all",
      conditionView: "ml-md-auto hide-on-mobile",
      stackedStatus: "md",
      //sort direction list for mobile view
      directions: [
        { key: false, label: "Asc", sortable: true },
        { key: true, label: "Desc", sortable: true },
      ],
      //END FIELD FOR MOBILE
      fields: [
        { key: "index", label: "No" },
        { key: "sapSo", label: "SAP SO", filterable: true, sortable: true },
        { key: "sapDo", label: "SAP DO", filterable: true, sortable: true },
        {
          key: "deliveryDate",
          label: "Delivery Date",
          filterable: true,
          sortable: true,
        },
        { key: "status", label: "STATUS", filterable: true, sortable: true },
        { key: "actions", label: "Actions" },
      ],
      shippingPoint:"1001",
      priority:"03",
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      isLoading: false,
      tableLoading: true,
      sortBy: "", //this for mobile view
      sortDesc: false, //this for mobile view
      showSortBy: false, //this for mobile view
      search: "",
      selectData: "",
      modalCollect: false,
      modalCustomer: false,
      listStatus: ["Scanning Completed", "Collected", "All"],
      defaultStatus: "Scanning Completed",
      deliveryStatus: "",

      
      delivDate: "", //model for search data by delivery date
    };
  },
  computed: {
    filterDelivery() {
      var result = this.deliveryOrders;

      if (this.deliveryStatus == "All") {
        result = result.filter(
          (x) => x.status === "Collected" || x.status === "Scanning Completed"
        );
      } else {
        result = result.filter((x) => x.status === this.deliveryStatus);
      }

      return result;
    },
    deliveryOrders() {
      var tempDO = this.$store.getters["delivery/getDO"].filter(
        (x) => x.priority === "03"
      );
      return tempDO;
    },
    permission() {
      let result = userAccess("Self Collect Daikin", "self_collect_menu");
      // console.log("Self Collect Daikin",result)
      return result;
    },
    // Create an options list from our fields
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    // Create an direction list that can be saved in session
    directionOptions() {
      return this.directions
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  created() {
    document.title = "Self Collect | RSP";

    
  },
  mounted() {
    // this.$store.dispatch("delivery/getDeliveryOrder");

    //temporary for detail dropdown button conditional by mobile view or desktop view
    this.mobileViewQuery.addListener(() => {
      this.mobileView = this.mobileViewQuery.matches;
    });

    // Saving Menu Setting on localstorage session so it still same even after reloading the page
    if (this.$session.has("perPageSelfCollectDaikin")) {
      this.perPage = this.$session.get("perPageSelfCollectDaikin");
    }
    if (this.$session.has("sortBySelfCollectDaikin")) {
      this.sortBy = this.$session.get("sortBySelfCollectDaikin");
    }
    if (this.$session.has("sortDescSelfCollectDaikin")) {
      this.sortDesc = this.$session.get("sortDescSelfCollectDaikin");
    }
    var isMobile = window.matchMedia("(max-width: 761px)").matches;
    console.log("mobile is ", isMobile);
    var queryAvailable = -1;
    var query;
    if (this.$route.query.status) {
      query = this.$route.query.status;
      queryAvailable = this.listStatus.findIndex((x) => {
        return x == query;
      });
    }
    console.log("queryavailable ", queryAvailable);
    if (queryAvailable >= 0) {
      if (isMobile) {
        this.btnForMobile(query);
        return
      }
      this.deliveryStatus = query;
    } else {
      this.deliveryStatus = this.defaultStatus;
    }
    
    if (this.$route.query.sapDo) {
      this.search = this.$route.query.sapDo;
      this.doFiltering()
    }
  },
  watch: {
    // Taking the Menu Setting from localstorage session so the setting will be the same as before
    perPage(perPageNew) {
      this.$session.set("perPageSelfCollectDaikin", perPageNew);
    },
    sortBy(sortByNew) {
      this.$session.set("sortBySelfCollectDaikin", sortByNew);
    },
    sortDesc(sortDescNew) {
      this.$session.set("sortDescSelfCollectDaikin", sortDescNew);
    },
  },
  methods: {
    ...mapActions({
      testScanDO: "delivery/updateDO",
      getDeliveryOrder: "delivery/getDeliveryOrder",
    }),
    onPageChange(page) {
      this.currentPage = page;
      this.queryDelivery();
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      console.log("this.totalRows", this.totalRows);
      this.tableLoading = false;
    },
    dates(date) {
      return dateFormat(date);
    },
    cleanQuery() {
      this.delivDate = "";
      // this.$store.dispatch("delivery/getDeliveryOrder");
      this.currentPage = 1;
      this.queryDelivery();
    },
    /*
    doFiltering: lodash.debounce(function(value) {
      this.search = value;
      this.currentPage = 1;
      this.queryDelivery();
    }, 500),
    */
    doFiltering() {
      this.isLoading = true
      //this.search = value;
      this.currentPage = 1;
      this.queryDelivery();
    },
    queryDelivery() {
      let wantedStatus = ["Collected", "Scanning Completed"];
      this.getDeliveryOrder({
        shippingPoint:this.shippingPoint,
        priority:this.priority,
        page: this.currentPage,
        entry: this.perPage,
        sapDo: this.search,
        status:
          this.deliveryStatus == "All" ? wantedStatus : this.deliveryStatus,
        delivDate: this.delivDate,
      }).then((data) => {
        this.isLoading = false
        this.processMetadata(data.metadata);
      }).catch((e) => {
        this.$bvToast.toast("Load data failed, please reload the page.", {
        title: "Warning",
        variant: "danger",
        solid: true,
        });
        console.log(e);
      });
    },
    fireFiltering(value) {
      this.delivDate = value;
      this.currentPage = 1;
      this.queryDelivery();
      //this is for datepicker load store data with query
      // this.$store.dispatch("delivery/getDeliveryOrder", { delivDate: value });
    },
    statusFiltering(value) {
      this.deliveryStatus = value;
      // this.currentPage = 1;
      // this.queryDelivery();
    },
    //METHOD FOR MOBILE VIEW
    btnForMobile(filter) {
      this.deliveryStatus = filter;
      this.btnBackMobile = "hide-on-desktop";
      this.conditionView = "ml-md-auto show-on-desktop";
      this.btnshow = false;
      this.showDropdown = false;
      this.showSortBy = true;
    },
    actBackMobile() {
      this.btnBackMobile = "hide-at-all";
      this.conditionView = "ml-md-auto hide-on-mobile";
      this.btnshow = true;
      this.showDropdown = true;
      this.showSortBy = false;
    },
    //END METHOD FOR MOBILE VIEW
    checkDetail(row) {
      var result;
      if (
        row.status !== "Scanning Completed" || !this.permission.collected
      ) {
        result = true;
      } else {
        result = false;
      }
      return result;
    },
    checkCollected(row) {
      var show;

      if (row.status === "Scanning Completed" && this.permission.collected) {
        show = true;
      } else {
        show = false;
      }
      return show;
    },
    toSelfCollect(data) {
      let idDO = data._id;
      this.$router.push({
        name: "warehouse-detail-for-completed",
        params: { id: idDO, from: "sc-daikin",status:this.deliveryStatus },
      });
    },
    toDetailPage(data) {
      let idDO = data._id;
      this.$router.push({
        name: "warehouse-detail-delivery",
        params: { id: idDO, from: "sc-daikin",status:this.deliveryStatus },
      });
    },
  },
};
</script>
<style scope>
.hide-at-all {
  display: none !important;
}

/* For mobile phones: */
@media (max-width: 761px) {
  .hide-on-mobile {
    display: none !important;
  }
}

/* For computer: */
@media (min-width: 761px) {
  .hide-on-desktop {
    display: none !important;
  }
  .show-on-desktop {
    display: inline !important;
  }
}
</style>
